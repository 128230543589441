import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import shortid from 'shortid'
import Section from '../molecules/Section'
import Member, { MemberItem } from '../molecules/Member'
import { markdownify } from '../../utils'
import styles from './SectionFounders.module.css'

const query = graphql`
  query SectionFoundersQuery {
    allTeamCoreJson {
      edges {
        node {
          github
          linkedin
          name
          bio
          twitter
          website
          position
          image {
            childImageSharp {
              ...MemberPhoto
            }
          }
        }
      }
    }
  }
`

export interface Publication {
  name: string
  logo: {
    publicURL: string
  }
  url: string
}

export default function SectionFounders() {
  const data = useStaticQuery(query)
  const team = data.allTeamCoreJson.edges

  const founders = team.filter(
    ({ node }: { node: MemberItem }) =>
      node.name.includes('Trent') || node.name.includes('Bruce')
  )

  return (
    <Section title="Founders" headerCenter className={styles.section}>
      <div className={styles.grid}>
        {founders.map(({ node }: { node: MemberItem }) => (
          <div key={shortid.generate()}>
            <Member member={node} />
            {node.bio && (
              <div className={styles.bio}>{markdownify(node.bio)}</div>
            )}
          </div>
        ))}
      </div>
    </Section>
  )
}
