import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import shortid from 'shortid'
import Section from '../../molecules/Section'
import PressRelease, { PressReleaseItem } from './PressRelease'
import styles from './index.module.css'

const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { slug: { glob: "**/press/**", ne: "/press/README" } } }
      sort: { fields: fields___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fixed(width: 1000) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
          }
          fields {
            slug
            date
          }
        }
      }
    }
  }
`

export default function SectionPressReleases({ title }: { title: string }) {
  const data = useStaticQuery(query)
  const pressReleases = data.allMarkdownRemark.edges

  return (
    <Section title={title}>
      <div className={styles.grid}>
        <div>
          {pressReleases
            .slice(0, 1)
            .map(({ node }: { node: PressReleaseItem }) => (
              <PressRelease key={shortid.generate()} node={node} latest />
            ))}
        </div>
        <div>
          {pressReleases
            .slice(1, pressReleases.length)
            .map(({ node }: { node: PressReleaseItem }) => (
              <PressRelease key={shortid.generate()} node={node} />
            ))}
        </div>
      </div>
    </Section>
  )
}
