import React from 'react'
import { PageProps, graphql } from 'gatsby'
import Layout from '../components/Layout'
import ActionBar from '../components/molecules/ActionBar'
import SectionMediaMentions from '../components/organisms/SectionMediaMentions'
import SectionPressReleases from '../components/organisms/SectionPressReleases'
import SectionFounders from '../components/organisms/SectionFounders'

export default function PageGatsbyPress(props: PageProps) {
  const {
    title,
    description,
    actions,
    titlePressReleases
  } = (props.data as any).content.edges[0].node.childPressJson

  return (
    <Layout
      title={title}
      description={description}
      uri={props.uri}
      location={props.location}
    >
      <>
        <ActionBar actions={actions} />
        <SectionPressReleases title={titlePressReleases} />
        <SectionFounders />
        <SectionMediaMentions />
      </>
    </Layout>
  )
}

export const contentQuery = graphql`
  query PressPageQuery {
    content: allFile(filter: { relativePath: { eq: "press/press.json" } }) {
      edges {
        node {
          childPressJson {
            title
            description
            actions {
              title
              description
              url
            }
            titlePressReleases
          }
        }
      }
    }
  }
`
