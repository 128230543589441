import React from 'react'
import { SocialIconLink } from '../../atoms/SocialIcon'
import { MemberItem } from '.'
import styles from './MemberLinks.module.css'

const utm = '?utm_source=oceanprotocol.com'

export default function MemberLinks({ member }: { member: MemberItem }) {
  return (
    <div className={styles.links}>
      {member.website && (
        <SocialIconLink
          url={`${member.website}${utm}`}
          title="Website"
          icon="website"
        />
      )}
      {member.linkedin && (
        <SocialIconLink
          url={`https://www.linkedin.com/in/${member.linkedin}`}
          title="LinkedIn"
          icon="linkedin"
        />
      )}
      {member.twitter && (
        <SocialIconLink
          url={`https://twitter.com/${member.twitter}`}
          title="Twitter"
          icon="twitter"
        />
      )}
      {member.github && (
        <SocialIconLink
          url={`https://github.com/${member.github}`}
          title="GitHub"
          icon="github"
        />
      )}
    </div>
  )
}
