import React from 'react'
import Img, { FluidObject } from 'gatsby-image'
import MemberLinks from './MemberLinks'
import styles from './index.module.css'
import { graphql } from 'gatsby'

export interface MemberItem {
  name: string
  image: { childImageSharp: { fluid: FluidObject } }
  github?: string
  linkedin?: string
  twitter?: string
  website?: string
  position?: string
  quote?: string
  link?: string
  bio?: string
}

export default function Member({ member }: { member: MemberItem }) {
  const { name, image, position, quote, link } = member

  return (
    <div className={`${styles.member} ${quote && styles.actionable}`}>
      <Img
        className={styles.photo}
        fluid={image?.childImageSharp?.fluid}
        alt={name}
        Tag="figure"
      />
      {quote && (
        <div className={styles.quote}>
          {quote}

          {link && <a href={link}>More info</a>}
        </div>
      )}
      <h3 className={styles.name}>{name}</h3>
      {position && <div className={styles.position}>{position}</div>}
      <MemberLinks member={member} />
    </div>
  )
}

export const memberPhoto = graphql`
  fragment MemberPhoto on ImageSharp {
    fluid(maxWidth: 250, maxHeight: 250, grayscale: true) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
    }
  }
`
