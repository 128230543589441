import React from 'react'
import { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import Time from '../../atoms/Time'
import styles from './PressRelease.module.css'

export interface PressReleaseItem {
  frontmatter: {
    title: string
    description: string
    image?: { childImageSharp: { fixed: FixedObject } }
  }
  fields: {
    slug: string
    date: string
  }
}

export default function PressRelease({
  node,
  latest
}: {
  node: PressReleaseItem
  latest?: boolean
}) {
  return (
    <Link
      to={node.fields.slug}
      className={latest ? styles.postLatest : styles.postSmall}
    >
      {latest ? (
        <>
          <figure
            className={styles.image}
            style={{
              backgroundImage: `url(${node.frontmatter.image.childImageSharp.fixed.src})`
            }}
          />
          <div className={styles.content}>
            <h4 className={styles.title}>{node.frontmatter.title}</h4>
            <p className={styles.description}>{node.frontmatter.description}</p>
            <Time date={node.fields.date} className={styles.date} />
          </div>
        </>
      ) : (
        <>
          <h4 className={styles.title}>{node.frontmatter.title}</h4>
          <Time date={node.fields.date} className={styles.date} />
        </>
      )}
    </Link>
  )
}
